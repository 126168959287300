<template>
  <v-dialog
    persistent
    fullscreen
    v-model="active"
    @keydown.esc="closeDialog"
    :width="`${screenWidth}px`"
    :height="`${screenHeight}px`"
  >
    <!-- 
    
   -->
    <div
      :style="{
        width: `${screenWidth}px`,
        height: `${screenHeight}px`,
        padding: `${paddingContentGallery}`,
      }"
      class="
        container-dialog
        display-flex
        align-items-center
        justify-content-center
      "
    >
      <v-btn @click="closeDialog" icon dark class="button-close">
        <v-icon color="white"> mdi-close </v-icon>
      </v-btn>
      <div class="width-100 height-100 position-relative">
        <v-btn @click="prev" @keydown.37="prev" icon class="button-carousel-left">
          <v-icon color="white" size="30px"> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn @click="next"  @keydown.39="next" icon class="button-carousel-right">
          <v-icon color="white" size="30px"> mdi-chevron-right </v-icon>
        </v-btn>
        <div class="content-image-max">
          <img :src="image" alt="" />
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "GalleryComponent",
  data() {
    return {
      //VARIABLES
      screenWidth: 0,
      screenHeight: 0,
      image: "",
      paddingContentGallery: "0% 0%",
    };
  },
  created() {

    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    prev() {
      let num = this.imageSelected;

      if (this.imageSelected > 0) {
        num = num - 1;
        this.$store.commit("setGalleryImageSelected", num);
      }
    },
    next() {
      let num = this.imageSelected;
      if (this.imageSelected < this.allImages.length - 1) {
        num = num + 1;
        this.$store.commit("setGalleryImageSelected", num);
      }
    },
    handleResize: function () {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;

      if (window.innerWidth < 600) {
        this.paddingContentGallery = "35% 5%";
      } else if (window.innerWidth > 600 && window.innerWidth < 960) {
        this.paddingContentGallery = "20% 5%";
      } else if (window.innerWidth > 961 && window.innerWidth < 1264) {
        this.paddingContentGallery = "5% 10%";
      } else if (window.innerWidth > 1265 && window.innerWidth < 1904) {
        this.paddingContentGallery = "5% 15%";
      } else if (window.innerWidth > 1905) {
        this.paddingContentGallery = "5% 20%";
      }
    },
    closeDialog: function () {
      this.$store.commit("setGallery", {
        active: false,
        imageSelected: "",
        imagesArr: [],
      });
      this.$store.commit("setGalleryImageSelected", 0);
      this.image = "";
    },
  },
  computed: {
    active: function () {
      return this.$store.state.galleryActive;
    },
    imageSelected: function () {
      return this.$store.state.galleryImageSelected;
    },
    allImages: function () {
      return this.$store.state.gallerAllImages;
    },
  },
  watch: {
    active: function () {
      if (this.active) {
        this.image = this.allImages[this.imageSelected].oImages.lg;
      }
    },
    imageSelected: function () {
      if (this.active) {
        this.image = this.allImages[this.imageSelected].oImages.lg;
      }
    },
  },
};
</script>

<style scoped>
.button-carousel-left {
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: -15px;
  background: black;
  backdrop-filter: saturate(180%) blur(10px);
  min-width: 40px;
  height: 40px;
  border: solid 2px white;
}

.button-carousel-right {
  position: absolute;
  top: 50%;
  bottom: 50%;
  right: -15px;
  background: black;
  backdrop-filter: saturate(180%) blur(10px);
  min-width: 40px;
  height: 40px;
  border: solid 2px white;
}

.content-image-max {
  width: 100%;
  height: 100%;
  background-color: #000;
}

.position-relative {
  position: relative;
}

.content-image-max img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image-max img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

.container-dialog {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: saturate(180%) blur(10px);
  overflow: hidden;
}

.button-close {
  background: black;
  backdrop-filter: saturate(180%) blur(10px);
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>